import Swiper from "swiper";
import '../Slider/assets/style.scss';



window.slider = function slider(container = 'swiper-container', slide= 'fade', delay= 8000, grab=false) {
    return new Swiper('.' + container, {
        effect: slide,
        grabCursor:grab,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: delay,
            disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        slidesPerView: 1,
    });
};

